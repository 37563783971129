import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';

import { useSecurity } from '../../auth';
import { MainMenu, MainMenuOption } from '../../components/MainMenu';
import { GTAG_EVENT } from '../../gtag';
import { noop } from '../../util/function';
import { usePreferencesManager } from '../user-preferences';

const convertOldSettings = pm => {
  const oldSetting = pm.get('currency');
  pm.set('useUsdAmount', oldSetting === 'USD');
  pm.delete('currency');
};

const getInitialState = pm => {
  convertOldSettings(pm);
  let v1 = pm.get('useUsdAmount');
  let v2 = pm.get('includeTaxes');
  let v3 = pm.get('useLLY');
  return {
    useUsdAmount: v1 !== undefined ? Boolean(v1) : false,
    includeTaxes: v2 !== undefined ? Boolean(v2) : false,
    useLLY: v3 !== undefined ? Boolean(v3) : false,
  };
};

const UserShell = ({ children, onClose = noop, history }) => {
  const pm = usePreferencesManager();
  const [settings, setSettings] = useState(getInitialState(pm));
  const { isAuthenticated, userName, instance } = useSecurity();
  const [drawer, setDrawer] = useState(false);

  const toggleSelect = () => {
    setDrawer(true);
  };

  const handleClickLogOut = async () => {
    GTAG_EVENT({ view: 'logout' });
    await instance.logoutRedirect();
  };

  const handleIncludeTaxesChange = (name, value) => {
    pm.set('includeTaxes', value);
    setSettings({
      ...settings,
      includeTaxes: value,
    });
    GTAG_EVENT({
      view: 'setting_change',
      category: 'Include taxes',
      label: value ? 'yes' : 'no',
    });
  };
  const handleLLYChange = (name, value) => {
    pm.set('useLLY', value);
    setSettings({
      ...settings,
      useLLY: value,
    });
    GTAG_EVENT({
      view: 'setting_change',
      category: 'use LLY',
      label: value === true ? 'yes' : 'no',
    });
  };

  const handleCurrencyAsUsdChange = (name, value) => {
    pm.set('useUsdAmount', value);
    setSettings({
      ...settings,
      useUsdAmount: value,
    });
    GTAG_EVENT({
      view: 'setting_change',
      category: 'Reporting currency',
      label: value === true ? 'yes' : 'no',
    });
  };

  const handleKPIDefinitionClick = () => {
    history.push('/kpi-definition');
    setDrawer(false);
  };

  return (
    <>
      {children(settings, toggleSelect)}
      <Drawer
        anchor="right"
        open={drawer}
        onClose={_ => {
          onClose();
          setDrawer(false);
        }}
      >
        <MainMenu
          userName={userName}
          settings={settings}
          showLogOut={isAuthenticated}
          onClickLogOut={handleClickLogOut}
        >
          <MainMenuOption
            label="Currencies as USD"
            name="useUsdAmount"
            checked={settings.useUsdAmount}
            onChange={handleCurrencyAsUsdChange}
          />
          <MainMenuOption
            label="Include taxes"
            name="includeTaxes"
            checked={settings.includeTaxes}
            onChange={handleIncludeTaxesChange}
          />
          <MainMenuOption
            label="use LLY"
            name="useLLY"
            checked={settings.useLLY}
            onChange={handleLLYChange}
          />
          <MainMenuOption
            label="KPI Definition"
            switchComponent={false}
            onClickComponent={true}
            onChange={handleKPIDefinitionClick}
          />
        </MainMenu>
      </Drawer>
    </>
  );
};

UserShell.propTypes = {
  onClose: PropTypes.func,
};

export default withRouter(UserShell);
